<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_scuola">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        :initial-values="defaultValues"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ values }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Richiesta Scuola Tennis</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="new-off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="ps-5 pe-5">
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Stagione</label>
                      <SelectInput
                        :options="anni"
                        name="anno"
                        placeholder="-"
                        @changeSelect="
                          scuola.id_Tipologia = null;
                          getTipologieScuole($event);
                        "
                        :value="anni[0].label"
                        disabled
                      />
                      <!-- <Field
                      type="text"
                      class="form-control"
                      :value="new Date().getFullYear()"
                      name="anno"
                      disabled
                    /> -->
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data richiesta</label
                      >
                      <div>
                        <button
                          @click.prevent="
                            isOpenDatepicker = !isOpenDatepicker;
                            openDatepicker(isOpenDatepicker);
                          "
                          class="bugSolver"
                        ></button>
                        <Datepicker
                          v-model="scuola.data_Richiesta"
                          ref="datepickerBug"
                          placeholder=""
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                          @update:model-value="
                            scuola.data_Richiesta = fromUTCtoISODate($event)
                          "
                        />
                        <Field
                          name="data_Richiesta"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.data_Richiesta"
                        />
                        <ErrorMessage
                          name="data_Richiesta"
                          style="color: red"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Data scadenza</label>
                      <Datepicker
                        v-model="scuola.data_Scadenza"
                        :maxDate="maxData_Scadenza"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        @update:model-value="
                          scuola.data_Scadenza = fromUTCtoISODate($event)
                        "
                      />
                      <Field
                        name="data_Scadenza"
                        type="hidden"
                        class="form-control"
                        v-model="scuola.data_Scadenza"
                      />
                      <ErrorMessage name="data_Scadenza" style="color: red" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Disciplina*</label>
                      <SelectInput
                        :options="discipline"
                        name="id_Disciplina"
                        placeholder="-"
                        @changeSelect="
                          values.id_Tipologia = null;
                          getTipologieScuole($event);
                          resetFigure();
                          id_Disciplina = $event;
                          dirigenteObbligatorio = $event == 4332 ? true : false;
                        "
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Tipologia*</label>
                      <SelectInput
                        :options="scuole_tipo"
                        name="id_Tipologia"
                        :value="id_Tipologia"
                        placeholder="-"
                        @changeSelect="
                          dirigenteObbligatorio =
                            id_Disciplina == 4332 && $event != 1 ? true : false;
                          setTipologia($event);
                        "
                        :disabled="!id_Disciplina"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Progetto materna*</label
                      >
                      <SelectInput
                        :options="lookupProgettoSiNo"
                        name="progettomaterna"
                        placeholder="-"
                        :value="null"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Progetto elementari*</label
                      >
                      <SelectInput
                        :options="lookupProgettoSiNo"
                        name="progettoelementari"
                        placeholder="-"
                        :value="null"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data inizio corsi*</label
                      >
                      <Datepicker
                        modelType="yyyy/mm/dd"
                        v-model="scuola.data_Inizio_Corsi"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        @update:model-value="
                          scuola.data_Inizio_Corsi = fromUTCtoISODate($event)
                        "
                      />
                      <Field
                        name="data_Inizio_Corsi"
                        type="hidden"
                        class="form-control"
                        v-model="scuola.data_Inizio_Corsi"
                      />
                      <ErrorMessage
                        name="data_Inizio_Corsi"
                        style="color: red"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data fine corsi*</label
                      >
                      <Datepicker
                        modelType="yyyy/mm/dd"
                        v-model="scuola.data_Fine_Corsi"
                        :minDate="
                          new Date(
                            scuola.data_Inizio_Corsi
                              ? scuola.data_Inizio_Corsi
                              : ''
                          )
                        "
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        @update:model-value="
                          scuola.data_Fine_Corsi = fromUTCtoISODate($event)
                        "
                      />
                      <Field
                        name="data_Fine_Corsi"
                        type="hidden"
                        class="form-control"
                        v-model="scuola.data_Fine_Corsi"
                      />
                      <ErrorMessage name="data_Fine_Corsi" style="color: red" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Partecipanti totali*</label
                      >
                      <Field
                        name="numero_Partecipanti"
                        type="text"
                        class="form-control"
                      />
                      <ErrorMessage
                        name="numero_Partecipanti"
                        style="color: red"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Nuovi partecipanti*</label
                      >
                      <Field
                        name="numero_Nuovi"
                        type="text"
                        class="form-control"
                      />
                      <ErrorMessage name="numero_Nuovi" style="color: red" />
                    </div>
                  </div>
                  <hr class="mb-4 mt-4" />
                  <!--  <div v-show="anni[0].label && id_Disciplina"> -->
                  <div>
                    <p>Figure presenti all'interno della scuola:</p>
                    <div class="row mb-4">
                      <div class="col-md-5">
                        <label
                          class="fw-bold text-gray-600 pb-3"
                          label-for="disciplina"
                          >Qualifica*</label
                        >
                      </div>
                      <div class="col-md-6">
                        <label class="fw-bold text-gray-600 pb-3"
                          >Persona*</label
                        >
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-md-5">
                        <input
                          type="text"
                          class="form-control"
                          :value="
                            dirigenteObbligatorio == true
                              ? 'Dirigente*'
                              : 'Dirigente'
                          "
                          aria-label=""
                          readonly
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="dp__input_wrap">
                          <!---->
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            aria-label=""
                            @input="
                              getTecnici($event.target.value, 3);
                              inputFocus = 1;
                            "
                            v-model="scuola.dirigente"
                            autocomplete="new-off"
                            :disabled="!values.id_Tipologia"
                          />
                          <Field
                            name="id_Dirigente"
                            type="hidden"
                            class="form-control"
                            v-model="scuola.id_Dirigente"
                          />
                          <ErrorMessage
                            name="id_Dirigente"
                            style="color: red"
                          />
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              scuola.dirigente = null;
                              scuola.id_Dirigente = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListPersone
                          v-if="inputFocus === 1"
                          :no_new="true"
                          :list="tecnici"
                          :focusOnInput="inputFocus === 1"
                          @hideList="
                            resetTecnici();
                            inputFocus = null;
                          "
                          @selectedLegale="
                            scuola.id_Dirigente = $event.value;
                            scuola.dirigente = $event.name;
                            inputFocus = null;
                          "
                        ></ListPersone>
                      </div>
                      <div class="col-md-1 text-center">
                        <Popper
                          v-if="values.id_Tipologia"
                          arrow
                          :content="msgDirigente"
                          style="margin: -8px"
                        >
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </Popper>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-5">
                        <input
                          type="text"
                          class="form-control"
                          value="Dirigente responsabile*"
                          aria-label=""
                          readonly
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="dp__input_wrap">
                          <!---->
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            aria-label=""
                            @input="
                              getTecnici($event.target.value, 10);
                              inputFocus = 2;
                            "
                            v-model="scuola.responsabile"
                            :disabled="!values.id_Tipologia"
                          />
                          <Field
                            name="id_Responsabile"
                            type="hidden"
                            class="form-control"
                            v-model="scuola.id_Responsabile"
                            autocomplete="new-off"
                          />
                          <ErrorMessage
                            name="id_Responsabile"
                            style="color: red"
                          />
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              scuola.responsabile = null;
                              scuola.id_Responsabile = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListPersone
                          v-if="inputFocus === 2"
                          :no_new="true"
                          :list="tecnici"
                          :focusOnInput="inputFocus === 2"
                          @hideList="
                            resetTecnici();
                            inputFocus = null;
                          "
                          @selectedLegale="
                            scuola.id_Responsabile = $event.value;
                            scuola.responsabile = $event.name;
                            inputFocus = null;
                          "
                        ></ListPersone>
                      </div>
                      <div class="col-md-1 text-center">
                        <Popper
                          v-if="values.id_Tipologia"
                          arrow
                          :content="msgDirigenteResponsabile"
                          style="margin: -8px"
                        >
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </Popper>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-5">
                        <input
                          type="text"
                          class="form-control"
                          value="Direttore tecnico*"
                          aria-label=""
                          readonly
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="dp__input_wrap">
                          <!---->
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            aria-label=""
                            @input="
                              getTecnici($event.target.value, 1);
                              inputFocus = 3;
                            "
                            v-model="scuola.tecnico"
                            :disabled="!values.id_Tipologia"
                          />
                          <Field
                            name="id_Tecnico"
                            type="hidden"
                            class="form-control"
                            v-model="scuola.id_Tecnico"
                            autocomplete="new-off"
                          />
                          <ErrorMessage
                            name="id_Tecnico"
                            style="color: red"
                          /><svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              scuola.tecnico = null;
                              scuola.id_Tecnico = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListPersone
                          v-if="inputFocus === 3"
                          :no_new="true"
                          :list="tecnici"
                          :focusOnInput="inputFocus === 3"
                          @hideList="
                            resetTecnici;
                            inputFocus = null;
                          "
                          @selectedLegale="
                            scuola.id_Tecnico = $event.value;
                            scuola.tecnico = $event.name;
                            inputFocus = null;
                          "
                        ></ListPersone>
                      </div>
                      <div class="col-md-1 text-center">
                        <Popper
                          v-if="values.id_Tipologia"
                          arrow
                          :content="msgDirettoreTecnico"
                          style="margin: -8px"
                        >
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </Popper>
                      </div>
                    </div>

                    <div class="row mb-4">
                      <div class="col-md-5">
                        <input
                          type="text"
                          class="form-control"
                          value="Preparatore fisico*"
                          aria-label=""
                          readonly
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="dp__input_wrap">
                          <!---->
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            aria-label=""
                            @input="
                              getTecnici($event.target.value, 11);
                              inputFocus = 4;
                            "
                            v-model="scuola.preparatore_fisico"
                            :disabled="!values.id_Tipologia"
                          />
                          <Field
                            name="id_Preparatore_Fisico"
                            type="hidden"
                            class="form-control"
                            v-model="scuola.id_Preparatore_Fisico"
                            autocomplete="new-off"
                          />
                          <ErrorMessage
                            name="id_Preparatore_Fisico"
                            style="color: red"
                          />
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              scuola.preparatore_fisico = null;
                              scuola.id_Preparatore_Fisico = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListPersone
                          v-if="inputFocus === 4"
                          :no_new="true"
                          :list="tecnici"
                          :focusOnInput="inputFocus === 4"
                          @hideList="
                            resetTecnici;
                            inputFocus = null;
                          "
                          @selectedLegale="
                            scuola.id_Preparatore_Fisico = $event.value;
                            scuola.preparatore_fisico = $event.name;
                            inputFocus = null;
                          "
                        ></ListPersone>
                      </div>
                      <div class="col-md-1 text-center">
                        <Popper
                          v-if="values.id_Tipologia"
                          arrow
                          :content="msgPreparatoreFisico"
                          style="margin: -8px"
                        >
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </Popper>
                      </div>
                    </div>

                    <div class="row mb-4 pb-3">
                      <div class="col-md-5">
                        <input
                          type="text"
                          class="form-control"
                          value="Insegnante scuola*"
                          aria-label=""
                          readonly
                        />
                      </div>
                      <div class="col-md-6">
                        <div class="dp__input_wrap">
                          <!---->
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            aria-label=""
                            @input="
                              getTecnici($event.target.value, 1);
                              inputFocus = 5;
                            "
                            autocomplete="new-off"
                            v-model="scuola.insegnante_scuola"
                            :disabled="!values.id_Tipologia"
                          />
                          <Field
                            name="id_Insegnante_Scuola"
                            type="hidden"
                            class="form-control"
                            v-model="scuola.id_Insegnante_Scuola"
                          />
                          <ErrorMessage
                            name="id_Insegnante_Scuola"
                            style="color: red"
                          />
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                            @click="
                              scuola.insegnante_scuola = null;
                              scuola.id_Insegnante_Scuola = null;
                            "
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                        </div>
                        <ListPersone
                          v-if="inputFocus === 5"
                          :no_new="true"
                          :list="tecnici"
                          :focusOnInput="inputFocus === 5"
                          @hideList="
                            resetTecnici;
                            inputFocus = null;
                          "
                          @selectedLegale="
                            scuola.id_Insegnante_Scuola = $event.value;
                            scuola.insegnante_scuola = $event.name;
                            inputFocus = null;
                          "
                        ></ListPersone>
                      </div>
                      <div class="col-md-1 text-center">
                        <Popper
                          v-if="values.id_Tipologia"
                          arrow
                          :content="msgInsegnante"
                          style="margin: -8px"
                        >
                          <i class="bi bi-info-circle-fill text-center"></i>
                        </Popper>
                      </div>
                    </div>
                    <FieldArray
                      name="altreFigure"
                      v-slot="{ fields, push, remove }"
                    >
                      <div v-for="(field, index) in fields" :key="field.key">
                        <AggiungiFigura
                          :index="index"
                          @deleteItem="
                            remove(index);
                            count.splice(index, 1);
                          "
                          @setPersona="
                            count[index] = { ...count[index], ...$event }
                          "
                          @mountedComponent="
                            count[index] = {
                              id_Persona: null,
                              id_Tipo_Tecnico: null,
                              id: null,
                            }
                          "
                          :societa="societa"
                          :anni="anni"
                          :id_Disciplina="id_Disciplina"
                          :id_Tipologia="values.id_Tipologia"
                        />
                        <div class="row">
                          <div class="col-md-5">
                            <Field
                              :name="`altreFigure[${index}].id_Tipo_Tecnico`"
                              :id="index"
                              type="hidden"
                              v-model="count[index].id_Tipo_Tecnico"
                            />
                            <ErrorMessage
                              :name="`altreFigure[${index}].id_Tipo_Tecnico`"
                              style="color: red"
                            />
                          </div>
                          <div class="col-md-5">
                            <Field
                              :name="`altreFigure[${index}].id_Persona`"
                              :id="index"
                              type="hidden"
                              v-model="count[index].id_Persona"
                            />
                            <ErrorMessage
                              :name="`altreFigure[${index}].id_Persona`"
                              style="color: red"
                            />
                          </div>
                        </div>
                      </div>
                      <hr class="mb-4 mt-4" />
                      <div class="fw-bold text-center pt-1">
                        <button
                          class="fw-bold fs-6 text-gray-700"
                          @click.prevent="
                            count.push({
                              id_Persona: 0,
                              id_Tipo_Tecnico: 0,
                              id: 0,
                            });
                            push({
                              id_Persona: 0,
                              id_Tipo_Tecnico: 0,
                              id: 0,
                            });
                          "
                        >
                          <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi
                          figura
                        </button>
                      </div>
                    </FieldArray>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAddScuola"
            >
              Chiudi
            </button>
            <button
              type="reset"
              class="badge bg-danger rounded fs-6"
              @click="
                resetField();
                id_Disciplina = null;
              "
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Salva
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import { fromUTCtoISODate } from "@/composables/formatDate";
import { defineComponent, ref, getCurrentInstance, computed, watch } from "vue";
import { Form, Field, ErrorMessage, FieldArray, useForm } from "vee-validate";
import * as yup from "yup";
import "vue3-date-time-picker/dist/main.css";
import { format } from "@/composables/formatDate";
import ListPersone from "@/components/components-fit/utility/ListPersone.vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { alertSuccess, alertFailed } from "@/composables/swAlert";
import { addScuola } from "@/requests/scuoleRequest";
import AggiungiFigura from "@/components/components-fit/società/scuola-tennis/AggiungiFigura.vue";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import Popper from "vue3-popper";

export default defineComponent({
  name: "addScuolaTennis",
  emit: ["refreshScuoleSoc"],
  components: {
    Datepicker,
    Form,
    Field,
    FieldArray,
    ErrorMessage,
    ListPersone,
    SelectInput,
    AggiungiFigura,
    Loading,
    Popper,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
    anni: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    // bug datepicker
    const datepickerBug = ref(null);
    const isOpenDatepicker = ref(false);
    const openDatepicker = (bool) => {
      if (bool) {
        datepickerBug.value.openMenu();
        return;
      }
      datepickerBug.value.closeMenu();
    };
    // lookup
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tipo_tecnico = computed(() =>
      store.getters.getStateFromName("tipo_tecnico")
    );
    const keys = ref("d|tt");
    if (!discipline.value || !tipo_tecnico.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const id_Tipologia = ref(null);
    const getTipologieScuole = (id) => {
      if (id != 4332) {
        msgDirigente.value =
          "Il dirigente deve essere tesserato presso il circolo";
      } else {
        msgDirigente.value =
          "Il dirigente deve essere membro dell’albo dirigenti e deve essere tesserato presso il circolo";
      }
      store.commit("spliceArrayFromName", "scuole_tipo");
      store.dispatch("setStoreData", {
        keys: { id_Disciplina: id },
        apiLink: globalApi.LOOKUP_TIPO_SCUOLE,
      });
      id_Tipologia.value = null;
    };

    const msgDirigenteResponsabile = ref(
      "Il dirigente responsabile deve essere membro del consiglio e deve essere tesserato presso il circolo"
    );
    const msgDirigente = ref(
      "Il dirigente deve essere membro dell’albo dirigenti e deve essere tesserato presso il circolo"
    );
    const msgDirettoreTecnico = ref(
      "Il direttore tecnico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );
    const msgPreparatoreFisico = ref(
      "Il preparatore fisico deve essere tesserato presso il circolo"
    );
    const msgInsegnante = ref(
      "L'insegnante deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );

    const setTipologia = (event) => {
      id_Tipologia.value = event;
      if (event != 1) {
        msgPreparatoreFisico.value =
          "Il preparatore fisico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola.";
      } else {
        msgPreparatoreFisico.value =
          "Il preparatore fisico deve essere tesserato presso il circolo";
      }
    };

    // get tecnici
    const id_Disciplina = ref(null);
    const getTecnici = (string, tipo_Albo) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      // store.dispatch("getPersoneNomeCognome", string);
      store.dispatch("getTecniciNomeCognome", {
        string: string,
        anno: props.anni[0].label,
        id_Societa: props.societa ? props.societa.id : props.id_societa,
        tipo_Albo: tipo_Albo,
        id_Disciplina: id_Disciplina.value,
        id_tipologia_scuola: id_Tipologia.value,
      });
    };
    const resetTecnici = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const inputFocus = ref(0);

    //oggetto scuola
    //
    const maxData_Scadenza = ref(new Date());

    const setDataCorsi = () => {
      const Year = props.anni[0].label;
      maxData_Scadenza.value = new Date(Year + "-07-31");
    };

    const scuola = ref({
      id_Societa: props.societa.id,
      data_Richiesta: fromUTCtoISODate(new Date()),
      data_Scadenza: fromUTCtoISODate(maxData_Scadenza.value),
      id_Disciplina: null,
      id_Tipologia: null,
      id_Dirigente: null,
      id_Responsabile: null,
      id_Tecnico: null,
      id_Preparatore_Fisico: null,
      id_Insegnante_Scuola: null,
      data_Inizio_Corsi: null,
      data_Fine_Corsi: null,
    });

    watch(
      () => props.anni,
      (value) => {
        if (value) {
          setDataCorsi();
          scuola.value.data_Scadenza = fromUTCtoISODate(maxData_Scadenza.value);
        }
      }
    );
    const dirigenteObbligatorio = ref(false);

    // validazione e richiesta add
    const { resetForm } = useForm();
    const schema = yup.object().shape({
      //anno deve essere precompilato
      // DATA RICHIESTA PRECOMPILATO
      // anno: yup
      //   .number()
      //   .required("Obbligatorio")
      //   .transform((value) => (isNaN(value) ? undefined : value)),
      data_Richiesta: yup.string().required("Obbligatorio").nullable(),
      data_Scadenza: yup.string().required("Obbligatorio").nullable(),
      id_Tipologia: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      id_Dirigente: yup.number().when("id_Disciplina", {
        is: () => dirigenteObbligatorio.value == true,
        then: yup
          .number()
          .required("Obbligatorio")
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup.number().nullable(),
      }),
      id_Responsabile: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Tecnico: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Preparatore_Fisico: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Insegnante_Scuola: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      progettomaterna: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      progettoelementari: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      numero_Partecipanti: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      numero_Nuovi: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Disciplina: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      data_Inizio_Corsi: yup.string().required("Obbligatorio").nullable(),
      data_Fine_Corsi: yup.string().required("Obbligatorio").nullable(),
      altreFigure: yup.array().of(
        yup.object({
          id_Persona: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .nullable()
            .min(1, "Obbligatorio"),
          // .transform((value) => (isNaN(value) ? undefined : value)),
          id_Tipo_Tecnico: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required("Obbligatorio")
            .nullable()
            .min(1, "Obbligatorio")
            .transform((value) => (isNaN(value) ? undefined : value)),
        })
      ),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);
    const alertCampiObb = ref(false);

    const onSubmit = (values) => {
      alertCampiObb.value = false;
      // values.carica viene caricato dal componente Aggiungi figura, nel suo SelectInput
      disableButton.value = true;
      isLoading.value = true;
      delete values.carica;
      if (values.teacher != undefined) {
        values.altreFigure.forEach((element) => {
          element.id_Societa = props.societa.id;
        });
      }
      values = {
        id_Societa: props.societa.id,
        ...values,
      };
      addScuola(values).then((res) => {
        disableButton.value = false;
        isLoading.value = false;

        if (res.status != 200) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          return;
        }
        alertSuccess("Richiesta inviata con successo");
        emit("refreshScuoleSoc");
        document.getElementById("closeModalAddScuola").click();
        resetField();
        resetFigure();
        defaultValues();
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const count = ref([]);

    const resetField = () => {
      store.commit("spliceArrayFromName", "scuole_tipo");
      scuola.value = {
        id_Societa: props.societa.id,
        data_Richiesta: fromUTCtoISODate(new Date()),
        data_Scadenza: fromUTCtoISODate(maxData_Scadenza.value),
      };
    };

    const defaultValues = {
      id_Tipologia: 0,
    };

    const resetFigure = () => {
      scuola.value.dirigente = null;
      scuola.value.id_Dirigente = null;
      scuola.value.responsabile = null;
      scuola.value.id_Responsabile = null;
      scuola.value.tecnico = null;
      scuola.value.id_Tecnico = null;
      scuola.value.preparatore_fisico = null;
      scuola.value.id_Preparatore_Fisico = null;
      scuola.value.insegnante_scuola = null;
      scuola.value.id_Insegnante_Scuola = null;
    };

    return {
      resetFigure,
      defaultValues,
      datepickerBug,
      isOpenDatepicker,
      openDatepicker,
      format,
      resetField,
      resetForm,
      discipline,
      scuole_tipo: computed(() =>
        store.getters.getStateFromName("scuole_tipo")
      ),
      getTipologieScuole,
      lookupProgettoSiNo: [
        { id: 0, label: "No" },
        { id: 1, label: "Si" },
      ],
      stagioni: computed(() => store.getters.stagioni),
      tipo_tecnico,
      tecnici: computed(() => store.getters.personeSocieta),

      scuola,
      onSubmit,
      onInvalidSubmit,
      getTecnici,
      resetTecnici,
      inputFocus,
      schema,
      disableButton,
      isLoading,

      count,
      fromUTCtoISODate,
      id_Disciplina,
      alertCampiObb,

      maxData_Scadenza,
      dirigenteObbligatorio,
      setTipologia,

      msgDirigenteResponsabile,
      msgDirigente,
      msgDirettoreTecnico,
      msgPreparatoreFisico,
      msgInsegnante,
    };
  },
});
</script>

<style scoped>
/* tooltip */
:deep(.popper) {
  background: #fff !important;
  padding: 5px !important;
  border-radius: 5px !important;
  border: 1px solid #8b8b8b !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #fff !important;
  border: 1px solid #8b8b8b;
  left: 0px !important;
  top: -1px !important;
  visibility: visible !important;
  content: "" !important;
  transform: rotate(225deg) !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fff !important;
  left: 0px !important;
}
</style>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
.bugSolver {
  position: absolute;
  top: 3rem;
  z-index: 2;
}
</style>
