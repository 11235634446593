<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row justify-content-between">
      <div class="col-6">
        <div>
          <button
            v-if="!readOnly && puo_utilizzare_pulsanti_sezioni"
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_scuola"
            @click.prevent="canOpenModal()"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>nuova richiesta
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Scuole
          <b>{{ scuole_societa_list ? scuole_societa_list.length : 0 }}</b>
        </div>
      </div>
    </div>
    <br />

    <!-- inserita da me come in TableSocieta.vue -->

    <!-- template Silvia -->
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        scuole_societa_list.length === 0 &&
        loaded &&
        (status == 200 || status == 204) === true
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>

      <!-- <span class="sr-only">Loading...</span> -->
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="scuole_societa_list"
        :total="record"
        :enableItemsPerPageDropdown="false"
      >
        <template v-slot:cell-anno="{ row: data }">
          {{ data.anno }}
        </template>
        <template v-slot:cell-disciplina="{ row: data }"
          ><div class="">
            {{ data.disciplina }}
          </div>
        </template>
        <template v-slot:cell-tipologia="{ row: data }"
          ><div class="">
            {{ data.tipologia }}
          </div>
        </template>
        <template v-slot:cell-data_richiesta="{ row: data }"
          ><div class="text-center">
            {{ data.data_richiesta }}
          </div>
        </template>
        <template v-slot:cell-data_inizio_corsi="{ row: data }"
          ><div class="text-center">
            {{ data.data_inizio_corsi }}
          </div>
        </template>
        <template v-slot:cell-data_fine_corsi="{ row: data }"
          ><div class="text-center">
            {{ data.data_fine_corsi }}
          </div>
        </template>
        <template v-slot:cell-stato="{ row: data }">
          <h1
            :class="
              data.stato === 'Approvata'
                ? 'badge badge-light-success'
                : data.stato === 'Approvata temporaneamente'
                ? 'badge badge-light-warning'
                : data.stato === 'In approvazione'
                ? 'badge badge-light-warning'
                : data.stato === 'Rifiutata'
                ? 'badge badge-light-danger'
                : 'badge bagde-light'
            "
          >
            {{ data.stato }}
          </h1>
        </template>
        <template v-slot:cell-options="{ row: data }"
          ><div
            v-if="
              isEnabled('fnSocietaScuoleTennisEditScuola') &&
              puo_utilizzare_pulsanti_sezioni
            "
            class=""
          >
            <div class="btn-group">
              <button
                v-if="data.stato != 'Approvata' && data.stato != 'Rifiutata'"
                class="btn btn-sm dropdown"
                type="button"
                @click="
                  selectedScuolaId = data.id;
                  selectedScuolaStato = data.stato;
                  onlyView = false;
                "
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_scuola"
              >
                <i class="bi bi-pen text-dark fs-4"></i>
              </button>
              <button
                v-if="data.stato == 'Approvata' || data.stato == 'Rifiutata'"
                class="btn btn-sm dropdown"
                type="button"
                @click="
                  selectedScuolaId = data.id;
                  selectedScuolaStato = data.stato;
                  onlyView = true;
                "
                data-bs-toggle="modal"
                data-bs-target="#modal_edit_scuola"
              >
                <i class="bi bi-eye text-dark fs-4"></i>
              </button>
            </div>
          </div>
        </template>
      </Datatable>
    </div>
    <EditScuolaTennis
      :societa="societa"
      :id_scuola="selectedScuolaId"
      :stato="selectedScuolaStato"
      @refreshScuoleSoc="refreshScuoleSoc"
      :onlyView="onlyView"
    />
    <AddScuolaTennis
      :societa="societa"
      @refreshScuoleSoc="refreshScuoleSoc"
      :anni="anni"
    />
    <!-- template Silvia: paginazione già inclusa nel template -->
  </div>
</template>

<script>
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import AddScuolaTennis from "@/components/components-fit/società/scuola-tennis/AddScuolaTennis.vue";
import EditScuolaTennis from "@/components/components-fit/società/scuola-tennis/EditScuolaTennis.vue";
import {
  defineComponent,
  onMounted,
  getCurrentInstance,
  computed,
  ref,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import {
  canRequestScuole,
  checkAffiliazioneAnno,
} from "@/requests/scuoleRequest";
import { alertFailed } from "@/composables/swAlert";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  components: {
    AddScuolaTennis,
    EditScuolaTennis,
    Datatable,
    Loading,
  },
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  name: "scuole-tennis",
  setup(props) {
    const tableHeader = ref([
      {
        name: "Stagione",
        key: "anno",
        sortable: false,
      },
      {
        name: "Disciplina",
        key: "disciplina",
        sortable: false,
      },
      {
        name: "Tipologia",
        key: "tipologia",
        sortable: false,
      },
      {
        name: "Data Richiesta",
        key: "data_richiesta",
        sortable: false,
        align: "center",
      },
      {
        name: "Data Inizio Corsi",
        key: "data_inizio_corsi",
        sortable: false,
        align: "center",
      },
      {
        name: "Data Fine Corsi",
        key: "data_fine_corsi",
        sortable: false,
        align: "center",
      },
      {
        name: "Stato",
        key: "stato",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    // stagione da store
    const stagione = computed(() => store.getters.stagioneSelected);
    watch(stagione, () => {
      getScuoleTennis();
    });

    const getScuoleTennis = () => {
      store.dispatch("setStoreListData", {
        keys: {
          anno: stagione.value,
          id_societa: props.societa.id,
        },
        apiLink: globalApi.SCUOLE_SOCIETA_RICHIESTE,
        itemName: "scuole_societa_list",
      });
    };
    getScuoleTennis();

    const refreshScuoleSoc = () => {
      getScuoleTennis();
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Scuole Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });

    // const  =
    const isLoading = ref(false);
    const selectedScuolaId = ref(0);
    const selectedScuolaStato = ref(null);
    const anni = ref([10000]);
    const annoAttuale = ref(new Date().getFullYear());
    const canOpenModal = async () => {
      isLoading.value = true;
      await canRequestScuole().then(async (res) => {
        isLoading.value = false;
        if (res.data && res.data.statusCode == 400) {
          alertFailed(res.data.message, "kt_modal_add_scuola");
          return;
        }
        if (res.length == 0) {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "kt_modal_add_scuola"
          );
          return;
        } else {
          anni.value = res.stagioni_richiesta_scuole;
          await checkAffiliazioneAnno({
            anno: annoAttuale.value,
            id_societa: props.societa.id,
          }).then((res) => {
            const status = res.status;
            const affiliata = res.data.results
              ? res.data.results[0].affiliata
              : false;
            if (status != 200) {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            if (!affiliata) {
              alertFailed(
                `La Società non è affiliata per l'anno ${annoAttuale.value}`,
                "kt_modal_add_scuola"
              );
              return;
            }
          });
        }
      });
      selectedScuolaId.value = null;
      selectedScuolaStato.value = null;
    };

    const onlyView = ref(false);

    return {
      canOpenModal,
      tableHeader,
      anni,
      isEnabled,
      scuole_societa_list: computed(() =>
        store.getters.getStateFromName("resultsscuole_societa_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedscuole_societa_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordscuole_societa_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusscuole_societa_list")
      ),
      selectedScuolaId,
      selectedScuolaStato,
      refreshScuoleSoc,
      isLoading,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      onlyView,
      puo_utilizzare_pulsanti_sezioni: computed(
        () => store.getters.puo_utilizzare_pulsanti_sezioni
      ),
    };
  },
});
</script>

<style scoped>
.dropdown-menu {
  position: fixed !important;
}
</style>
