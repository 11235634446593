<template>
  <div class="modal fade" tabindex="-10" id="modal_edit_scuola">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
        v-slot="{ resetForm }"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Richiesta Scuola Tennis</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="card-body p-6">
              <form autocomplete="new-off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div
                  class="mx-auto my-auto text-center py-5 my-5"
                  v-if="isLoadingDetail"
                >
                  <div class="spinner-border text-gray-600" role="status"></div>
                  <span class="text-gray-600 ps-3 fs-2">Loading</span>
                </div>
                <div class="ps-5 pe-5" v-if="!isLoadingDetail">
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <!--@changeSelect="setRankingDisciplina($event)" -->
                      <label class="fw-bold text-gray-600">Stagione</label>
                      <!-- <SelectInput
                      :options="stagioni"
                      name="anno"
                      placeholder="-"
                      :value="scuola.anno"
                      @changeSelect="scuola.anno = $event"
                    /> -->
                      <Field
                        name="anno"
                        type="text"
                        class="form-control"
                        disabled
                        v-model="scuola.anno"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data richiesta</label
                      >
                      <div>
                        <button
                          @click.prevent="
                            isOpenDatepicker = !isOpenDatepicker;
                            openDatepicker(isOpenDatepicker);
                          "
                          class="bugSolver"
                        ></button>
                        <Datepicker
                          v-model="scuola.data_richiesta"
                          ref="datepickerBug"
                          placeholder=""
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                          @update:model-value="
                            scuola.data_richiesta = $event.toISOString()
                          "
                          :disabled="onlyView"
                        />
                        <Field
                          name="data_richiesta"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.data_richiesta"
                        />
                        <ErrorMessage
                          name="data_richiesta"
                          style="color: red"
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Data scadenza</label>
                      <Datepicker
                        modelType="yyyy/mm/dd"
                        v-model="scuola.data_scadenza"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        disabled
                        @update:model-value="
                          scuola.data_scadenza = $event.toISOString()
                        "
                      />
                      <Field
                        name="data_scadenza"
                        type="hidden"
                        class="form-control"
                        v-model="scuola.data_scadenza"
                      />
                      <ErrorMessage name="data_scadenza" style="color: red" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Disciplina*</label>
                      <SelectInput
                        :options="discipline"
                        name="id_Disciplina"
                        placeholder="-"
                        :value="scuola.id_disciplina"
                        @changeSelect="
                          scuola.id_disciplina = $event;
                          scuola.id_tipologia = null;
                          getTipologieScuole($event);
                          dirigenteObbligatorio = $event == 4332 ? true : false;
                        "
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600">Tipologia*</label>
                      <SelectInput
                        :options="scuole_tipo"
                        name="id_Tipologia"
                        placeholder="-"
                        :value="scuola.id_tipologia"
                        @changeSelect="
                          scuola.id_tipologia = $event;
                          dirigenteObbligatorio =
                            scuola.id_disciplina == 4332 && $event != 1
                              ? true
                              : false;
                          setTipologia($event);
                        "
                        :disabled="onlyView"
                      />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Progetto materna*</label
                      >
                      <SelectInput
                        :options="lookupProgettoSiNo"
                        name="progettomaterna"
                        placeholder="-"
                        :value="scuola.progettoMaterna"
                        @changeSelect="scuola.progettoMaterna = $event"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Progetto elementari*</label
                      >
                      <SelectInput
                        :options="lookupProgettoSiNo"
                        name="progettoelementari"
                        placeholder="-"
                        :value="scuola.progettoElementari"
                        @changeSelect="scuola.progettoElementari = $event"
                        :disabled="onlyView"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data inizio corsi*</label
                      >
                      <Datepicker
                        modelType="yyyy/mm/dd"
                        v-model="scuola.data_inizio_corsi"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        @update:model-value="
                          scuola.data_inizio_corsi = $event.toISOString()
                        "
                        :disabled="onlyView"
                      />
                      <Field
                        name="data_inizio_corsi"
                        type="hidden"
                        class="form-control"
                        v-model="scuola.data_inizio_corsi"
                      />
                      <ErrorMessage
                        name="data_inizio_corsi"
                        style="color: red"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Data fine corsi*</label
                      >
                      <Datepicker
                        modelType="yyyy/mm/dd"
                        v-model="scuola.data_fine_corsi"
                        placeholder=""
                        :format="format"
                        :monthChangeOnScroll="false"
                        :enableTimePicker="false"
                        inputClassName="h-28"
                        autoApply
                        @update:model-value="
                          scuola.data_fine_corsi = $event.toISOString()
                        "
                        :disabled="onlyView"
                      />
                      <Field
                        name="data_fine_corsi"
                        type="hidden"
                        class="form-control"
                        v-model="scuola.data_fine_corsi"
                      />
                      <ErrorMessage name="data_fine_corsi" style="color: red" />
                    </div>
                  </div>
                  <div class="row pb-4">
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Partecipanti totali*</label
                      >
                      <Field
                        name="numero_Partecipanti"
                        type="text"
                        class="form-control"
                        v-model="scuola.numero_partecipanti"
                        :disabled="onlyView"
                      />
                      <ErrorMessage
                        name="numero_Partecipanti"
                        style="color: red"
                      />
                    </div>
                    <div class="col-md-3">
                      <label class="fw-bold text-gray-600"
                        >Nuovi partecipanti*</label
                      >
                      <Field
                        name="numero_Nuovi"
                        type="text"
                        class="form-control"
                        v-model="scuola.numero_nuovi"
                        :disabled="onlyView"
                      />
                      <ErrorMessage name="numero_Nuovi" style="color: red" />
                    </div>
                  </div>
                  <hr class="mb-4 mt-4" />
                  <p>Figure presenti all'interno della scuola:</p>
                  <div class="row mb-4">
                    <div class="col-md-5">
                      <label class="fw-bold text-gray-600 pb-3">Persona*</label>
                      <div class="dp__input_wrap">
                        <!---->
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            getTecnici($event.target.value, 3);
                            inputFocus = 'id_dirigente';
                          "
                          v-model="scuola.dirigente"
                          autocomplete="new-off"
                          :disabled="onlyView"
                        />
                        <Field
                          name="id_Dirigente"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.id_dirigente"
                        />
                        <ErrorMessage name="id_Dirigente" style="color: red" />
                        <svg
                          v-if="!onlyView"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            scuola.id_dirigente = null;
                            scuola.dirigente = '';
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListPersone
                        :list="tecnici"
                        :focusOnInput="inputFocus === 'id_dirigente'"
                        @hideList="
                          resetTecnici();
                          inputFocus = null;
                        "
                        @selectedLegale="
                          scuola.id_dirigente = $event.value;
                          scuola.dirigente = $event.name;
                          resetTecnici();
                          inputFocus = null;
                        "
                      ></ListPersone>
                    </div>
                    <div class="col-md-5">
                      <label
                        class="fw-bold text-gray-600 pb-3"
                        label-for="disciplina"
                        >Qualifica*</label
                      >
                      <select class="form-select" disabled>
                        <option selected>
                          Dirigente{{
                            dirigenteObbligatorio == true ? "*" : ""
                          }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="col-md-1 text-center"
                      style="align-self: flex-end"
                    >
                      <Popper
                        v-if="scuola.id_tipologia"
                        arrow
                        :content="msgDirigente"
                        style="margin: -8px"
                      >
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </Popper>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-5">
                      <div class="dp__input_wrap">
                        <!---->
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            getTecnici($event.target.value, 10);
                            inputFocus = 'id_responsabile';
                          "
                          v-model="scuola.responsabile"
                          autocomplete="new-off"
                          :disabled="onlyView"
                        />
                        <Field
                          name="id_Responsabile"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.id_responsabile"
                        />
                        <ErrorMessage
                          name="id_Responsabile"
                          style="color: red"
                        />
                        <svg
                          v-if="!onlyView"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            scuola.id_responsabile = null;
                            scuola.responsabile = '';
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListPersone
                        :list="tecnici"
                        :focusOnInput="inputFocus === 'id_responsabile'"
                        @hideList="
                          resetTecnici();
                          inputFocus = null;
                        "
                        @selectedLegale="
                          scuola.id_responsabile = $event.value;
                          scuola.responsabile = $event.name;
                          inputFocus = null;
                          resetTecnici();
                        "
                      ></ListPersone>
                    </div>
                    <div class="col-md-5">
                      <select class="form-select" disabled>
                        <option selected>Dirigente responsabile*</option>
                      </select>
                    </div>
                    <div class="col-md-1 text-center">
                      <Popper
                        v-if="scuola.id_tipologia"
                        arrow
                        :content="msgDirigenteResponsabile"
                        style="margin: -8px"
                      >
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </Popper>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-5">
                      <div class="dp__input_wrap">
                        <!---->
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            getTecnici($event.target.value, 1);
                            inputFocus = 'id_tecnico';
                          "
                          v-model="scuola.tecnico"
                          autocomplete="new-off"
                          :disabled="onlyView"
                        />
                        <Field
                          name="id_Tecnico"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.id_tecnico"
                        />
                        <ErrorMessage name="id_Tecnico" style="color: red" />
                        <svg
                          v-if="!onlyView"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            scuola.id_tecnico = null;
                            scuola.tecnico = '';
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListPersone
                        :list="tecnici"
                        :focusOnInput="inputFocus === 'id_tecnico'"
                        @hideList="
                          resetTecnici();
                          inputFocus = null;
                        "
                        @selectedLegale="
                          scuola.id_tecnico = $event.value;
                          scuola.tecnico = $event.name;
                          resetTecnici();
                          inputFocus = null;
                        "
                      ></ListPersone>
                    </div>
                    <div class="col-md-5">
                      <select class="form-select" disabled>
                        <option selected>Direttore tecnico*</option>
                      </select>
                    </div>
                    <div class="col-md-1 text-center">
                      <Popper
                        v-if="scuola.id_tipologia"
                        arrow
                        :content="msgDirettoreTecnico"
                        style="margin: -8px"
                      >
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </Popper>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-md-5">
                      <div class="dp__input_wrap">
                        <!---->
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            getTecnici($event.target.value, 11);
                            inputFocus = 'id_preparatore_fisico';
                          "
                          v-model="scuola.preparatore_fisico"
                          autocomplete="new-off"
                          :disabled="onlyView"
                        />
                        <Field
                          name="id_Preparatore_Fisico"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.id_preparatore_fisico"
                        />
                        <ErrorMessage
                          name="id_Preparatore_Fisico"
                          style="color: red"
                        />
                        <svg
                          v-if="!onlyView"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            scuola.id_preparatore_fisico = null;
                            scuola.preparatore_fisico = '';
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListPersone
                        :list="tecnici"
                        :focusOnInput="inputFocus === 'id_preparatore_fisico'"
                        @hideList="
                          resetTecnici();
                          inputFocus = null;
                        "
                        @selectedLegale="
                          scuola.id_preparatore_fisico = $event.value;
                          scuola.preparatore_fisico = $event.name;
                          resetTecnici();
                          inputFocus = null;
                        "
                      ></ListPersone>
                    </div>
                    <div class="col-md-5">
                      <select class="form-select" disabled>
                        <option selected>Preparatore Fisico*</option>
                      </select>
                    </div>
                    <div class="col-md-1 text-center">
                      <Popper
                        v-if="scuola.id_tipologia"
                        arrow
                        :content="msgPreparatoreFisico"
                        style="margin: -8px"
                      >
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </Popper>
                    </div>
                  </div>

                  <div class="row mb-4 pb-3">
                    <div class="col-md-5">
                      <div class="dp__input_wrap">
                        <!---->
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            getTecnici($event.target.value, 1);
                            inputFocus = 'id_insegnante_scuola';
                          "
                          v-model="scuola.insegnante_scuola"
                          autocomplete="new-off"
                          :disabled="onlyView"
                        />
                        <Field
                          name="id_Insegnante_Scuola"
                          type="hidden"
                          class="form-control"
                          v-model="scuola.id_insegnante_scuola"
                        />
                        <ErrorMessage
                          name="id_Insegnante_Scuola"
                          style="color: red"
                        />
                        <svg
                          v-if="!onlyView"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            scuola.id_insegnante_scuola = null;
                            scuola.insegnante_scuola = '';
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg>
                      </div>
                      <ListPersone
                        :list="tecnici"
                        :focusOnInput="inputFocus === 'id_insegnante_scuola'"
                        @hideList="
                          resetTecnici();
                          inputFocus = null;
                        "
                        @selectedLegale="
                          scuola.id_insegnante_scuola = $event.value;
                          scuola.insegnante_scuola = $event.name;
                          resetTecnici();
                          inputFocus = null;
                        "
                      ></ListPersone>
                    </div>
                    <div class="col-md-5">
                      <select class="form-select" disabled>
                        <option selected>Insegnante scuola*</option>
                      </select>
                    </div>
                    <div class="col-md-1 text-center">
                      <Popper
                        v-if="scuola.id_tipologia"
                        arrow
                        :content="msgInsegnante"
                        style="margin: -8px"
                      >
                        <i class="bi bi-info-circle-fill text-center"></i>
                      </Popper>
                    </div>
                  </div>
                  <!-- {{ scuola }} -->
                  <Datatable
                    v-if="scuola.altri_insegnanti"
                    :table-header="tableHeader"
                    :table-data="scuola.altri_insegnanti"
                    :enableItemsPerPageDropdown="false"
                    :rowsPerPage="1000"
                  >
                    <template v-slot:cell-persona="{ row: data, index: i }">
                      <div class="dp__input_wrap tab-long">
                        <!-- <FieldArray
                        name="altri_insegnanti"
                        v-slot="{ push, remove }"
                      >
                      </FieldArray> -->
                        <input
                          type="text"
                          :name="`altreFigure[${i}].nome_insegnante`"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            getTecnici($event.target.value, 1);
                            inputFocus = data.id;
                          "
                          v-model="data.nome_insegnante"
                          autocomplete="new-off"
                          disabled
                        />
                        <Field
                          :name="`altreFigure[${i}].id_Persona`"
                          :id="i"
                          type="hidden"
                          v-model="data.id_persona"
                        />
                        <Field
                          :name="`altreFigure[${i}].id`"
                          :id="i"
                          type="hidden"
                          v-model="data.id"
                        />

                        <!--  <svg
                          v-if="!onlyView"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          class="dp__icon dp__clear_icon dp__input_icons"
                          @click="
                            scuola.altri_insegnanti[i].id_persona = null;
                            scuola.altri_insegnanti[i].nome_insegnante = '';
                          "
                        >
                          <path
                            d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                          ></path>
                          <path
                            d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                          ></path>
                        </svg> -->
                      </div>
                      <!--  <ListPersone
                        :list="tecnici"
                        :focusOnInput="inputFocus === data.id"
                        @hideList="
                          resetTecnici();
                          inputFocus = null;
                        "
                        @selectedLegale="
                          scuola.altri_insegnanti[i].id_persona = $event.value;
                          scuola.altri_insegnanti[i].nome_insegnante =
                            $event.name;
                          resetTecnici();
                          inputFocus = null;
                        "
                      ></ListPersone> -->
                    </template>
                    <template v-slot:cell-tecnico="{ row: data, index: i }"
                      ><div class="tab-long">
                        <input
                          v-if="data.id_tipo_tecnico === null"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          :value="data.tecnico"
                          disabled
                        />
                        <!-- tipo_tecnico -->
                        <SelectInput
                          :options="tipo_tecnico"
                          :name="`altreFigure[${i}].id_Tipo_Tecnico`"
                          placeholder="-"
                          :value="data.id_tipo_tecnico"
                          @changeSelect="
                            scuola.altri_insegnanti[i].id_tipo_tecnico = $event
                          "
                          :disabled="onlyView"
                        />
                      </div>
                    </template>
                    <template v-slot:cell-options="{ row: data, index: i }"
                      ><div class="" @click="selectedScuolaId = data.id">
                        <div class="btn-group">
                          <button
                            class="btn btn-sm dropdown"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            @click="scuola.altri_insegnanti.splice(i, 1)"
                          >
                            <i class="bi bi-trash text-danger fs-5"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                  </Datatable>
                  <FieldArray
                    v-if="(scuola.stato != 4 && scuola.stato != 3) || !onlyView"
                    name="altreFigureAdd"
                    v-slot="{ fields, push, remove }"
                  >
                    <div v-for="(field, index) in fields" :key="field.key">
                      <AggiungiFigura
                        v-if="count[index]"
                        :index="index"
                        @deleteItem="
                          remove($event);
                          count.splice(index, 1);
                        "
                        @setPersona="
                          count[index] = { ...count[index], ...$event }
                        "
                        :id_Disciplina="scuola.id_disciplina"
                        :anni="scuola.anno"
                        :societa="societa"
                        :id_Societa="id_societa"
                        :key="field.key"
                        :id_Tipologia="scuola.id_tipologia"
                      />
                      <!-- @mountedComponent="
                          count[index] = {
                            id_Persona: 0,
                            id_Tipo_Tecnico: 0,
                            id: null,
                          }
                        " -->
                      <Field
                        v-if="count[index]"
                        :name="`altreFigureAdd[${index}].id_Persona`"
                        :id="`altreFigureAdd[${index}].id_Persona`"
                        type="hidden"
                        v-model="count[index].id_Persona"
                      />
                      <ErrorMessage
                        :name="`altreFigureAdd[${index}].id_Persona`"
                        style="color: red"
                      />
                      <Field
                        v-if="count[index]"
                        :name="`altreFigureAdd[${index}].id_Tipo_Tecnico`"
                        :id="`altreFigureAdd[${index}].id_Tipo_Tecnico`"
                        type="hidden"
                        v-model="count[index].id_Tipo_Tecnico"
                      />
                      <ErrorMessage
                        :name="`altreFigureAdd[${index}].id_Tipo_Tecnico`"
                        style="color: red"
                      />
                    </div>
                    <hr class="mb-4 mt-4" />
                    <div class="fw-bold text-center pt-1">
                      <button
                        class="fw-bold fs-6 text-gray-700"
                        @click.prevent="
                          count.push({
                            id_Persona: 0,
                            id_Tipo_Tecnico: 0,
                            id: null,
                          });
                          push({
                            id_Persona: 0,
                            id_Tipo_Tecnico: 0,
                            id: null,
                          });
                        "
                      >
                        <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi
                        figura
                      </button>
                    </div>
                  </FieldArray>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalEditScuola"
              @click="
                resetForm();
                $emit('resetids');
              "
            >
              Chiudi
            </span>
            <!-- <button
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              type="reset"
            >
              test reset
            </button> -->
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
              v-if="!onlyView"
            >
              Salva modifiche
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import { defineComponent, ref, watch, getCurrentInstance, computed } from "vue";
import { useStore } from "vuex";
import "vue3-date-time-picker/dist/main.css";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { Form, Field, ErrorMessage, FieldArray } from "vee-validate";
import * as yup from "yup";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { format } from "@/composables/formatDate";
import ListPersone from "@/components/components-fit/utility/ListPersone.vue";
import AggiungiFigura from "@/components/components-fit/società/scuola-tennis/AggiungiFigura.vue";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import Popper from "vue3-popper";

import {
  viewScuola,
  editScuola,
  addInsegnante,
} from "@/requests/scuoleRequest";
import { alertSuccess, alertFailed } from "@/composables/swAlert";

export default defineComponent({
  name: "EditScuolaTennis",
  emit: ["refreshScuoleSoc", "refreshScuole", "resetids"],
  props: {
    id_scuola: {
      type: Number,
      required: true,
    },
    societa: {
      type: Object,
      required: false,
    },
    id_societa: {
      type: Number,
      required: false,
    },
    stato: {
      type: [String, Number],
      required: true,
    },
    onlyView: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    // bug datepicker
    const datepickerBug = ref(null);
    const isOpenDatepicker = ref(false);
    const openDatepicker = (bool) => {
      if (bool) {
        datepickerBug.value.openMenu();
        return;
      }
      datepickerBug.value.closeMenu();
    };
    // header datapicker
    const tableHeader = ref([
      {
        name: "Persona",
        key: "persona",
        sortable: false,
      },
      {
        name: "Qualifica",
        key: "tecnico",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    //richiesta scuola
    const isLoadingDetail = ref(false);
    const alertCampiObb = ref(false);

    const scuola = ref({});
    const setScuola = async (id) => {
      isLoadingDetail.value = true;
      scuola.value = {};
      await viewScuola(id).then((res) => {
        isLoadingDetail.value = true;
        if (res) {
          scuola.value = res;
          setPreparatoreMsg(scuola.value.id_tipologia);
          setDirigenteMsg(scuola.value.id_disciplina);
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        isLoadingDetail.value = false;
      });
      getTipologieScuole(scuola.value.id_disciplina);
    };

    watch(
      () => props.id_scuola,
      async (value) => {
        if (value != null) await setScuola(value);
      }
    );
    // lookup
    const store = useStore();

    const dirigenteObbligatorio = ref(false);

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const tipo_tecnico = computed(() =>
      store.getters.getStateFromName("tipo_tecnico")
    );
    const keys = ref("d|tt");
    if (!discipline.value || !tipo_tecnico.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getTipologieScuole = (id) => {
      store.commit("spliceArrayFromName", "scuole_tipo");
      store.dispatch("setStoreData", {
        keys: { id_Disciplina: id },
        apiLink: globalApi.LOOKUP_TIPO_SCUOLE,
      });
    };

    // const resetFormFields = () => {
    //   resetField({numero_partecipanti: null});
    // };

    // validazione e richiesta add
    const schema = yup.object().shape({
      //anno deve essere precompilato
      // DATA RICHIESTA PRECOMPILATO
      anno: yup.number().required("Indicare la stagione"),
      // data_Richiesta: yup.required("Indicare la stagione"),
      // data_Scadenza: yup.required("Indicare la stagione"),
      id_Dirigente: yup.number().when("id_Disciplina", {
        is: () => dirigenteObbligatorio.value == true,
        then: yup
          .number()
          .required("Obbligatorio")
          .transform((value) => (isNaN(value) ? undefined : value)),
        otherwise: yup.number().nullable(),
      }),
      id_Responsabile: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Tecnico: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Preparatore_Fisico: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Insegnante_Scuola: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      progettomaterna: yup.number().required("Obbligatorio"),
      progettoelementari: yup.number().required("Obbligatorio"),
      // data_Inizio_Corsi: yup.number().required("Indicare progetto materna"),
      // data_Fine_Corsi: null,
      numero_Partecipanti: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      numero_Nuovi: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("id_Disciplina", {
          is: (id_Disciplina) => {
            if (id_Disciplina === 4332) {
              return true;
            }
            return false;
          },
          then: yup.number().required("Obbligatorio"),
          otherwise: yup.number().nullable(),
        }),
      id_Disciplina: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
      id_Tipologia: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      altreFigureAdd: yup.array().of(
        yup.object({
          id_Persona: yup
            .number()
            .required("Selezionare una persona ")
            /* .min(1, "Selezione una persona dalla lista") */
            .transform((value) => (isNaN(value) ? undefined : value)),
          id_Tipo_Tecnico: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .required(" Selezionare una qualifica "),
          /* .min(1, "Indicare la tipologia di insegnante"), */
        })
      ),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      if (values.altreFigureAdd && values.altreFigure) {
        values.altreFigureAdd.forEach((element) => {
          values.altreFigure.push(element);
        });
      } else if (values.altreFigureAdd && !values.altreFigure) {
        values.altreFigure = values.altreFigureAdd;
      }
      alertCampiObb.value = false;
      disableButton.value = true;
      isLoading.value = true;
      delete values.carica;

      if (props.id_societa) {
        values = {
          id: scuola.value.id,
          id_Societa: props.id_societa,
          ...values,
        };
      } else if (props.societa.id) {
        values = {
          id: scuola.value.id,
          id_Societa: props.societa.id,
          ...values,
        };
      }
      const scuolaEdited = ref({});
      scuolaEdited.value = editScuola(values).then((res) => {
        if (res.status == 200) {
          alertSuccess("Scuola modificata con successo");
          emit("refreshScuole");
          emit("refreshScuoleSoc");
          document.getElementById("closeModalEditScuola").click();
          setScuola(props.id_scuola);
          if (values.altreFigureAdd) {
            values.altreFigureAdd = [];
          }
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };
    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const getTecnici = (string, tipo_Albo) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      // store.dispatch("getPersoneNomeCognome", string);
      store.dispatch("getTecniciNomeCognome", {
        string: string,
        anno: scuola.value.anno,
        id_Societa: props.societa ? props.societa.id : props.id_societa,
        tipo_Albo: tipo_Albo,
        id_Disciplina: scuola.value.id_Disciplina,
        id_tipologia_scuola: scuola.value.id_tipologia,
      });
    };
    const resetTecnici = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const inputFocus = ref(0);
    const count = ref([]);

    const setIndex = ({ idx, page }) => {
      const elementToAdd = 10 * (page - 1);
      if (page === 1) return idx;
      else return idx + elementToAdd;
    };

    const msgDirigenteResponsabile = ref(
      "Il dirigente responsabile deve essere membro del consiglio e deve essere tesserato presso il circolo"
    );
    const msgDirigente = ref(
      "Il dirigente deve essere membro dell’albo dirigenti e deve essere tesserato presso il circolo"
    );
    const setDirigenteMsg = (event) => {
      if (event != 4332) {
        msgDirigente.value =
          "Il dirigente deve essere tesserato presso il circolo";
      } else {
        msgDirigente.value =
          "Il dirigente deve essere membro dell’albo dirigenti e deve essere tesserato presso il circolo";
      }
    };
    const msgDirettoreTecnico = ref(
      "Il direttore tecnico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );
    const msgPreparatoreFisico = ref(
      "Il preparatore fisico deve essere tesserato presso il circolo"
    );
    const setPreparatoreMsg = (event) => {
      if (event != 1) {
        msgPreparatoreFisico.value =
          "Il preparatore fisico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola.";
      } else {
        msgPreparatoreFisico.value =
          "Il preparatore fisico deve essere tesserato presso il circolo";
      }
    };
    const msgInsegnante = ref(
      "L'insegnante deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );

    const setTipologia = (event) => {
      if (event != 1) {
        msgPreparatoreFisico.value =
          "Il preparatore fisico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola.";
      } else {
        msgPreparatoreFisico.value =
          "Il preparatore fisico deve essere tesserato presso il circolo";
      }
    };

    return {
      setIndex,
      count,
      inputFocus,
      getTecnici,
      resetTecnici,
      format,
      isOpenDatepicker,
      openDatepicker,
      datepickerBug,

      scuola,

      discipline,
      scuole_tipo: computed(() =>
        store.getters.getStateFromName("scuole_tipo")
      ),
      getTipologieScuole,
      lookupProgettoSiNo: [
        { id: 0, label: "No" },
        { id: 1, label: "Si" },
      ],
      stagioni: computed(() => store.getters.stagioni),
      tipo_tecnico,
      // resetFormFields,
      onSubmit,
      onInvalidSubmit,
      tableHeader,

      tecnici: computed(() => store.getters.personeSocieta),
      schema,

      disableButton,
      isLoading,
      isLoadingDetail,
      alertCampiObb,
      dirigenteObbligatorio,

      msgDirigenteResponsabile,
      msgDirigente,
      msgDirettoreTecnico,
      msgPreparatoreFisico,
      msgInsegnante,
      setTipologia,
    };
  },

  components: {
    Datepicker,
    SelectInput,
    Form,
    Field,
    ErrorMessage,
    Datatable,
    ListPersone,
    AggiungiFigura,
    FieldArray,
    Loading,
    Popper,
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}
</style>

<style scoped>
/* tooltip */
:deep(.popper) {
  background: #fff !important;
  padding: 5px !important;
  border-radius: 5px !important;
  border: 1px solid #8b8b8b !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #fff !important;
  border: 1px solid #8b8b8b;
  left: 0px !important;
  top: -1px !important;
  visibility: visible !important;
  content: "" !important;
  transform: rotate(225deg) !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fff !important;
  left: 0px !important;
}
</style>
